import {
  Box,
  Card,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import * as apiService from "../../services/api/gameAPI";
import CreateHowToPlay from "components/HowToPlay/CreateDialog";
import DeleteHowToPlayDialog from "components/HowToPlay/DeleteDialog";
import YouTubePlayer from "components/YouTubePlayer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

const HowToPlay = () => {
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [loading, setLoading] = React.useState(false);
  const [howToPlaySteps, setHowToPlaySteps] = React.useState([]);
  const [refetchData, setRefetchData] = React.useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getHowToPlayData();
        console.log("response", response);
        setHowToPlaySteps(
          response?.data?.data ?? []
          // .filter((data) => data?.step !== null)
          // .sort((a, b) => a?.step - b?.step)
        );
        setCount(response?.data?.data?.length ?? 0);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [term, refetchData]);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            {/* {stickers.length === 0 ? (
              <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                There are no records at the moment.
              </div>
            ) : ( */}
            <>
              <Grid item xs={12}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "12px -1px",
                    }}
                  >
                    <MDBox
                      sx={{
                        p: 2,
                      }}
                    >
                      <CreateHowToPlay
                        handleRefetchData={() => setRefetchData(!refetchData)}
                        isEditable={false}
                        rowData={undefined}
                      />
                    </MDBox>
                  </div>
                  <TableContainer
                    sx={{
                      pl: 2,
                    }}
                  >
                    {howToPlaySteps?.length === 0 ? (
                      <>
                        {" "}
                        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                          There are no records at the moment.
                        </div>
                      </>
                    ) : (
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Title</StyledTableCell>
                            <StyledTableCell>Image</StyledTableCell>
                            <StyledTableCell>Media Type</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            {/* <StyledTableCell>Position</StyledTableCell> */}
                            <StyledTableCell>Edit</StyledTableCell>
                            <StyledTableCell>Delete</StyledTableCell>
                            {/* <StyledTableCell>File Id</StyledTableCell> */}
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {howToPlaySteps
                            // ?.filter((data) => data?.step !== null)
                            // ?.sort((a, b) => a?.step - b?.step)
                            ?.map((data, i) => (
                              <StyledTableRow key={i}>
                                <StyledTableCell>{data.title}</StyledTableCell>
                                <StyledTableCell>
                                  <Box>
                                    {data?.mediaType === "youtube" ? (
                                      <YouTubePlayer videoUrl={data?.ytUrl} />
                                    ) : (
                                      <img
                                        src={data?.image}
                                        alt="Image"
                                        height={100}
                                        width={300}
                                        style={{
                                          objectFit: "contain",
                                        }}
                                      />
                                    )}
                                  </Box>
                                </StyledTableCell>
                                <StyledTableCell
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {data.mediaType}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      width: "400px",
                                      height: "150px",
                                      overflowY: "auto",
                                    }}
                                  >
                                    {data.description}
                                  </Typography>
                                </StyledTableCell>
                                {/* <StyledTableCell>
                                  {data?.position === 1 ? "Right" : "Left"}
                                </StyledTableCell> */}
                                <StyledTableCell>
                                  <CreateHowToPlay
                                    handleRefetchData={() => setRefetchData(!refetchData)}
                                    // handleRefetchData={() => handleUpdate(data)}
                                    isEditable={true}
                                    rowData={data}
                                  />
                                </StyledTableCell>
                                <StyledTableCell>
                                  <DeleteHowToPlayDialog
                                    row={data}
                                    handleRefetchData={() => setRefetchData(!refetchData)}
                                  />
                                </StyledTableCell>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Card>
              </Grid>
            </>
            {/* )} */}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
};

export default HowToPlay;
