import MDBox from "components/MDBox";
import React from "react";
import OTPInput from "react-otp-input";
import PhoneInput from "react-phone-input-2";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Typography } from "@mui/material";

const PhoneVerify = ({ phone, onChange, otp, setOtp, enableOtp, isVerified }) => {
  return (
    <>
      <PhoneInput
        country={"us"}
        inputProps={{
          name: "phone",
          required: true,
          // autoFocus: true,
        }}
        fullWidth="true"
        specialLabel=""
        disabled={isVerified}
        inputStyle={{
          border: "none",
          width: "100%",
          display: "block",
          padding: "12px",
          background: "transparent",
          color: isVerified ? "grey" : "white",
          fontSize: "16px",
          // border: "1px solid #E0E0E0",
          border: "1px solid hsl(0, 0.00%, 50.80%)",
          borderRadius: "8px",
        }}
        // buttonStyle={{
        //   background: "#011F2D",
        //   border: "none",
        //   padding: "10px",
        //   borderRadius: "8px",
        // }}
        // containerStyle={{
        //   background: "#03364C",
        // }}
        disableDropdown
        value={phone}
        onChange={onChange}
      />
      {/* <MDBox display="flex" alignItems="center" mt={1}></MDBox> */}
      <MDBox
        display="flex"
        alignItems="center"
        mt={1}
        sx={{
          transition: "opacity 1s ease-in-out, transform 1s ease-in-out",
          opacity: isVerified ? 1 : 0,
          // transform: isVerified ? "scale(0.9)" : "scale(1)",
        }}
      >
        {isVerified && <VerifiedIcon style={{ color: "green", marginLeft: "10px" }} />}

        <Typography fontSize={10} color={"gray"}>
          {isVerified ? "Verified" : ""}
        </Typography>
      </MDBox>

      <div
        style={{
          transition: "opacity 1s ease-in-out, transform 1s ease-in-out",
          opacity: enableOtp ? 1 : 0,
          transform: enableOtp ? "scale(1)" : "scale(0.9)",
        }}
      >
        <OTPInput
          inputStyle={{
            width: !enableOtp ? "0px" : "40px",
            height: !enableOtp ? "0px" : "50px",
            border: "1px solid hsl(0, 0.00%, 50.80%)",
            borderRadius: !enableOtp ? "0px" : "8px",
            color: "white",
            background: "transparent",
          }}
          value={otp}
          onChange={(e) => {
            console.log(e);
            setOtp(e);
          }}
          numInputs={6}
          renderSeparator={<span style={{ width: "10px" }} />}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus={false}
        />
      </div>
    </>
  );
};

export default PhoneVerify;
