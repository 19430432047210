import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { blockChianChampsConfig } from "services/api/gameAPI";
import { getAppSymbol } from "utils/Token";

const ChampsConfig = ({ blockChainConfig }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      champsMiniToken: blockChainConfig?.champsMiniToken || "",
      champsTransactionCharge: blockChainConfig?.champsTransactionCharge || "",
    },
  });

  useEffect(() => {
    // Update form values when blockChainConfig changes
    if (blockChainConfig) {
      reset({
        champsMiniToken: blockChainConfig?.champsMiniToken || "",
        champsTransactionCharge: blockChainConfig?.champsTransactionCharge || "",
      });
    }
  }, [blockChainConfig, reset]); // it will trigger and reset the defaultValues

  const onSubmit = async (data) => {
    try {
      console.log("Form Submitted:", data);

      const body = {
        champsMiniToken: Number(data.champsMiniToken),
        champsTransactionCharge: Number(data.champsTransactionCharge),
      };
      await blockChianChampsConfig(body);
      alert("Champ configuration updated successfully!");
    } catch (error) {
      console.error(error);
      alert(`Failed to update ${getAppSymbol()} configuration. Please try again later.`);
    }
  };

  return (
    <Container maxWidth="sm">
      <Card elevation={3} sx={{ mt: 4, p: 4, display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography variant="h6">Champs Config</Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <FormControl>
              <Controller
                name="champsMiniToken"
                control={control}
                rules={{
                  required: "Withdraw minimum token is required",
                  min: { value: 0, message: "Minimum token must be greater than or equal to 0" },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Withdraw Minimum Token"
                    type="number"
                    size="small"
                    error={!!errors.champsMiniToken}
                    helperText={errors.champsMiniToken?.message}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": { MozAppearance: "textfield" },
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl>
              <Controller
                name="champsTransactionCharge"
                control={control}
                rules={{
                  required: "Transaction charge is required",
                  min: {
                    value: 0,
                    message: "Transaction charge must be greater than or equal to 0",
                  },
                  max: { value: 100, message: "Transaction charge cannot exceed 100%" },
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Transaction Charge (%)"
                    type="number"
                    size="small"
                    error={!!errors.champsTransactionCharge}
                    helperText={errors.champsTransactionCharge?.message}
                    sx={{
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                        display: "none",
                      },
                      "& input[type=number]": { MozAppearance: "textfield" },
                    }}
                  />
                )}
              />
            </FormControl>
          </Stack>

          <Box textAlign="center" mt={2}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Box>
        </form>
      </Card>
    </Container>
  );
};

export default ChampsConfig;
