// src/components/YouTubePlayer.js
import React, { useEffect } from "react";
import YouTube from "react-youtube";

const YouTubePlayer = ({ videoUrl }) => {
  // Extract the video ID from the URL
  //   const videoId = videoUrl.split("v=")[1]?.split("&")[0];
  const [videoId, setVideoId] = React.useState(null);

  const extractVideoId = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7] ? match[7] : null;
  };

  useEffect(() => {
    const videoId = extractVideoId(videoUrl);
    setVideoId(videoId);
  }, []);

  // Define the player options
  const opts = {
    height: "200",
    width: "340",
    playerVars: {
      autoplay: 1, // Autoplay the video when loaded
    },
  };

  // Render the YouTube video
  return (
    <div>
      {/* <YouTube videoId={"7PIji8OubXU"} opts={opts} /> */}
      {videoId ? (
        <YouTube videoId={videoId} opts={opts} />
      ) : (
        <p>Invalid YouTube URL - {videoUrl}</p>
      )}
    </div>
  );
};

export default YouTubePlayer;
