import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDButton from "components/MDButton";
import { Box, Card, Grid, Modal, Typography } from "@mui/material";

const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
};

const ExportData = ({ apiCall, modelTitle }) => {
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [exportLoader, setExportLoader] = React.useState(false);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  const submitExport = async () => {
    setExportLoader(true);
    if (!startDate || !endDate) {
      setExportLoader(false);
      return alert("Please select start and end date");
    }

    const start = startDate ? dayjs(startDate).format("YYYY-MM-DD") : "";
    const end = endDate ? dayjs(endDate).format("YYYY-MM-DD") : "";

    // Validate if endDate is not before startDate
    if (dayjs(end).isBefore(start)) {
      setExportLoader(false);
      return alert("End date cannot be earlier than start date.");
    }
    // Call API to export data
    // API call here

    try {
      const isSuccess = await apiCall({ start, end });

      if (isSuccess) {
        setOpen(false);
        handleReset();
      }
    } catch (error) {
      console.error(error);
    }

    setExportLoader(false);
  };

  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <>
      <div style={{ margin: "20px" }}>
        <MDButton
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          color="primary"
        >
          {exportLoader ? "Loading..." : "Export to Excel"}
        </MDButton>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "20px",
                  padding: 3,
                }}
              >
                <Typography variant="h6">{modelTitle || "Export to excel"}</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker", "DatePicker"]}>
                    <DatePicker
                      label="Start date"
                      value={startDate}
                      // onChange={(newValue) => setStartDate(newValue)}
                      onChange={(newValue) => {
                        setStartDate(newValue);
                        if (endDate && newValue && newValue?.isAfter(endDate)) {
                          setEndDate(null); // Reset end date if it's before new start date
                        }
                      }}
                    />
                    <DatePicker
                      label="End date"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      minDate={startDate} // Prevent selecting dates before startDate
                      disabled={!startDate} // Disable until start date is selected
                    />
                  </DemoContainer>
                </LocalizationProvider>

                <div style={{ display: "flex", gap: "20px", justifyContent: "center" }}>
                  <MDButton
                    variant="contained"
                    onClick={submitExport}
                    color="primary"
                    sx={{ width: "150px" }}
                  >
                    {exportLoader ? "Loading..." : "Export"}
                  </MDButton>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ExportData;
