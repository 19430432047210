import { Card, Container, Divider, Typography } from "@mui/material";
import React from "react";

const RowLabel = ({ label, value }) => {
  return (
    <div style={{ display: "flex", justifyContent: "start", color: "white", gap: 50 }}>
      <Typography variant="caption" sx={{ fontWeight: "bold", width: "50%" }}>
        {label}
      </Typography>
      <Typography
        variant="caption"
        sx={{
          textAlign: "start",
        }}
      >
        {value}
      </Typography>
    </div>
  );
};

const GameAddressLabel = ({ data }) => {
  return (
    <Container maxWidth="sm">
      <Card elevation={3} sx={{ mt: 4, p: 4, display: "flex", flexDirection: "column", gap: 4 }}>
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
          }}
        >
          Game Wallets
        </Typography>

        {/* CRYPTOS */}
        <Typography
          variant="body1"
          sx={{
            color: "white !important", // Force override styles
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Cryptos Game :
        </Typography>
        <RowLabel label={"Smart Contract"} value={data?.contractAddress} />
        <RowLabel label={"Admin Wallet"} value={data?.adminWallet} />
        <RowLabel label={"Platform Wallet"} value={data?.adminPriceDistWallet?.walletAddress} />

        <Divider sx={{ borderBottom: "1px solid white", my: 2 }} />

        {/* STOCKS */}
        <Typography
          variant="body1"
          sx={{
            color: "white !important", // Force override styles
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Stock Game :
        </Typography>
        <RowLabel label={"Smart Contract"} value={data?.stockGameSc?.contractAddress} />
        <RowLabel label={"Admin Wallet"} value={data?.stockGameSc?.adminWallet?.walletAddress} />
        <RowLabel
          label={"Platform Wallet"}
          value={data?.stockGameSc?.platformWallet?.walletAddress}
        />

        <Divider sx={{ borderBottom: "1px solid white", my: 2 }} />

        {/* Feeder Wallet */}
        <Typography
          variant="body1"
          sx={{
            color: "white !important", // Force override styles
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Feeder Wallet :
        </Typography>

        <RowLabel label={"Wallet Address"} value={data?.feedAdminWallet?.walletAddress} />

        <Divider sx={{ borderBottom: "1px solid white", my: 2 }} />

        <Typography
          variant="body1"
          sx={{
            color: "white !important", // Force override styles
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Champs Address :
        </Typography>

        <RowLabel label={"Smart Contract"} value={data?.champsSmartContract} />
        <Divider sx={{ borderBottom: "1px solid white", my: 2 }} />

        <Typography
          variant="body1"
          sx={{
            color: "white !important", // Force override styles
            fontSize: "1rem",
            fontWeight: "bold",
          }}
        >
          Others :
        </Typography>

        <RowLabel label={"Personal Wallet"} value={data?.personalWalletAddress} />
        <RowLabel label={"Treasury Wallet"} value={data?.treasuryWalletAddress} />
        <RowLabel label={"Txn Fee Received Wallet"} value={data?.tnxFeeWalAddress} />
      </Card>
    </Container>
  );
};

export default GameAddressLabel;
