/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
// import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import * as apiService from "../../services/api/gameAPI";
import AddStickers from "./AddStickers";
import ReactTelegramStickerViewer from "./ReactTelegramStickerViewer";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function Stickers() {
  const [stickers, setStickers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiService.getAllStickerWithPagination(page + 1, limit);
        console.log(response, "res");
        setStickers(response?.data?.data?.data ?? []);
        // console.log(response.data.stickers);
        setCount(response?.data?.data?.totalCount ?? 0);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    };

    fetchData();
  }, [page, limit, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            {/* {stickers.length === 0 ? (
              <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                There are no records at the moment.
              </div>
            ) : ( */}
            <>
              <Grid item xs={12}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      padding: "12px -1px",
                    }}
                  >
                    <MDBox
                      sx={{
                        p: 2,
                      }}
                    >
                      <AddStickers />
                    </MDBox>
                  </div>
                  <TableContainer
                    sx={{
                      pl: 2,
                    }}
                  >
                    {stickers?.length === 0 ? (
                      <>
                        {" "}
                        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                          There are no records at the moment.
                        </div>
                      </>
                    ) : (
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>Sticker</StyledTableCell>
                            {/* <StyledTableCell>File Id</StyledTableCell> */}
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {stickers?.map((sticker) => (
                            <StyledTableRow key={sticker._id}>
                              <StyledTableCell>{sticker.name}</StyledTableCell>
                              <StyledTableCell>
                                {sticker?.stickerUrl?.endsWith(".tgs") ? (
                                  <ReactTelegramStickerViewer
                                    tgsUrl={
                                      sticker?.stickerUrl
                                      // "https://api.telegram.org/file/bot7257066110:AAGZxJvLpqcutInZfEmySqP7Jo4wa5EmYRQ/stickers/file_62.tgs"
                                    }
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                ) : (
                                  <img
                                    src={sticker?.stickerUrl}
                                    alt="sticker"
                                    width={100}
                                    height={100}
                                  />
                                )}
                              </StyledTableCell>
                              {/* <StyledTableCell>{sticker.file_path_result?.file_id}</StyledTableCell> */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </>
            {/* )} */}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}

export default Stickers;
