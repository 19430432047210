import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
// import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { display, Stack } from "@mui/system";

import MDButton from "components/MDButton";

import MDBox from "components/MDBox";
import RHFUploadAvatar from "components/RHTextField/RHFUploadAvatar";
import { Controller, useForm, useWatch } from "react-hook-form";
import step from "assets/theme/components/stepper/step";
import { uploadFile } from "services/api/gameAPI";
import { createHowToPlaySteps } from "services/api/gameAPI";
import { editHowToPlaySteps } from "services/api/gameAPI";

const style = {
  position: "absolute",
  top: { xs: "50%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: 800 },
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 400, md: 380 },
  overflowY: "scroll",
};

export default function CreateHowToPlay({ handleRefetchData, isEditable, rowData }) {
  const [isAlertMessgOpen, setIsAlertMessg] = React.useState(false);

  const [alertOpen, setAlertOpen] = React.useState(false);
  const submit = () => {
    setAlertOpen(true);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = async () => {
    try {
      console.log(rowData);
      setOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleClose = () => setOpen(false);

  const [file, setFile] = React.useState(null);
  const [networkImg, setNetworkImg] = React.useState(rowData?.image ?? "");
  const [loading, setLoading] = React.useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      description: rowData?.description ?? "",
      position: rowData?.position === 1 ? "right" : "left", // Default to "left"
      step: rowData?.step ?? "",
    },
  });

  const mediaType = useWatch({
    control,
    name: "mediaType", // Watch the value of mediaType
  });

  React.useEffect(() => {
    if (rowData) {
      setValue("description", rowData?.description ?? "");
      setValue("position", rowData?.position === 1 ? "right" : "left");
      setValue("step", rowData?.step);
      setValue("mediaType", rowData?.mediaType ?? "image");
      setValue("ytUrl", rowData?.ytUrl ?? "");
      setValue("title", rowData?.title ?? "");
      setNetworkImg(rowData?.image);
    }
  }, [rowData, setValue]);

  const resetData = () => {
    reset({
      description: "",
      position: "left",
      step: "",
    });
    setNetworkImg(null);
    setFile(null);
  };

  const onSubmit = async (data) => {
    if (isEditable) {
      return await onSubmitEdit(data);
    } else {
      return await onSubmitCreate(data);
    }
  };

  const onSubmitCreate = async (data) => {
    if (loading) return;

    const { description, position, step, title, ytUrl, mediaType } = data;
    console.log(data);

    setLoading(true);

    try {
      let payload = {};

      if (mediaType === "youtube") {
        payload = {
          ytUrl: ytUrl,
        };
      } else {
        if (file === null) {
          alert("Please upload an image");
          setLoading(false);
          return;
        }

        // Upload the image
        const formData = new FormData();
        formData.append("file", file);
        formData.append("folderType", "HowToPlay");
        const fileResponse = await uploadFile(formData);

        console.log(fileResponse, "Upload File");

        // const fileResponse = {
        //   data: "https://the420link1.s3.amazonaws.com/HowToPlay/dcdba165-5a87-4820-99c3-82527b620699_step1.png",
        // };

        if (!fileResponse?.data) {
          alert("Something went wrong!");
          setLoading(false);
          return;
        }

        payload = {
          image: fileResponse?.data,
        };
      }

      // const body = {
      //   title: title,
      //   description: description,
      //   position: position === "right" ? 1 : 0,
      //   step: step ?? 0,
      //   // image: fileResponse?.data,
      //   mediaType: mediaType,
      //   // ytUrl: ytUrl,
      // };
      payload = {
        ...payload,
        title: title,
        description: description,
        position: position === "right" ? 1 : 0,
        step: step ?? 0,
        mediaType: mediaType,
      };

      console.log(payload, "Body");
      await createHowToPlaySteps(payload);
      if (handleRefetchData) handleRefetchData();
      alert("How to play step created successfully!");
      resetData();
      setOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error, "error message");
      // alert("Something went wrong!");

      if (error.message.includes("413")) {
        alert("File is too large! Please upload a smaller file.");
      } else {
        alert("Something went wrong! Please try again.");
      }
    }
    setLoading(false);
  };

  const onSubmitEdit = async (data) => {
    if (loading) return;

    const { description, position, step, title, ytUrl, mediaType } = data;
    console.log(data);

    setLoading(true);

    try {
      let payload = {};

      let fileResponseData = null;

      if (mediaType === "youtube") {
        payload = {
          ytUrl: ytUrl,
        };
      } else {
        if (file) {
          // Upload the image
          const formData = new FormData();
          formData.append("file", file);
          formData.append("folderType", "HowToPlay");
          const fileResponse = await uploadFile(formData);

          console.log(fileResponse, "Upload File");

          // const fileResponse = {
          //   data: "https://the420link1.s3.amazonaws.com/HowToPlay/dcdba165-5a87-4820-99c3-82527b620699_step1.png",
          // };

          if (!fileResponse?.data) {
            alert("Something went wrong! - upload file");
            setLoading(false);
            return;
          }

          // fileResponseData = fileResponse?.data;
          payload = {
            image: fileResponse?.data,
          };
        } else {
          payload = {
            image: networkImg,
          };
        }
      }

      // const body = {
      //   description: description,
      //   position: position === "right" ? 1 : 0,
      //   // step: step ?? 0,
      //   // image: fileResponseData ?? networkImg,
      // };

      payload = {
        ...payload,
        title: title,
        description: description,
        position: position === "right" ? 1 : 0,
        step: step ?? 0,
        mediaType: mediaType,
      };

      console.log(payload, "Body");
      await editHowToPlaySteps(rowData?._id, payload);
      if (handleRefetchData) handleRefetchData();
      alert("How to play step edited successfully!");
      setOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);

      if (error.message.includes("413")) {
        alert("File is too large! Please upload a smaller file.");
      } else {
        alert("Something went wrong! Please try again.");
      }
      // alert("Something went wrong!");
    }
    setLoading(false);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        {isEditable ? "Edit" : "Create"}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 2 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        {isEditable ? "Edit How To Play" : "Create How To Play"}
                      </Typography>
                      {mediaType === "image" || mediaType === "gif" ? (
                        <RHFUploadAvatar file={file} setFile={setFile} networkImage={networkImg} />
                      ) : null}
                      {/* <ProfilePictureUpload file={file} setFile={setFile} /> */}

                      <MDBox>
                        <Stack
                          width={{ sm: "300px", md: "500px" }}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"start"}
                        >
                          {/* <TextField
                            value={description}
                            type="text"
                            // onChange={(e) => setUsername(e.target.value)}
                            onChange={(e) => handleDescriptionChange(e)}
                            label="Description"
                            helperText={helperText}
                          />

                          <TextField
                            value={position}
                            // type="number"
                            onChange={(e) => setPosition(e.target.value)}
                            label="Position"
                          />

                          <MDButton
                            type="submit"
                            onClick={submit}
                            sx={{ textAlign: "center" }}
                            variant="gradient"
                            color="primary"
                          >
                            Create
                          </MDButton> */}

                          <form onSubmit={handleSubmit(onSubmit)}>
                            {/* Position Switch */}
                            {/* <Controller
                              name="position"
                              control={control}
                              render={({ field }) => (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      checked={field.value === "right"}
                                      onChange={(e) =>
                                        setValue("position", e.target.checked ? "right" : "left")
                                      }
                                    />
                                  }
                                  label={`Image position: ${field.value.toUpperCase()}`}
                                />
                              )}
                            /> */}

                            {/* Media Type */}
                            <MDBox sx={{ my: 2 }}>
                              <Controller
                                name="mediaType"
                                control={control}
                                rules={{ required: "MediaType is required" }}
                                render={({ field }) => (
                                  <FormControl fullWidth error={!!errors.mediaType}>
                                    <InputLabel id="media-type-label">Media Type</InputLabel>
                                    <Select
                                      {...field}
                                      labelId="media-type-label"
                                      id="media-type-select"
                                      label="Media Type"
                                      sx={{
                                        height: 45,
                                      }}
                                    >
                                      <MenuItem value="image">Image</MenuItem>
                                      {/* <MenuItem value="video">Video</MenuItem> */}
                                      <MenuItem value="gif">GIF</MenuItem>
                                      <MenuItem value="youtube">YouTube</MenuItem>
                                    </Select>
                                    {errors.mediaType && (
                                      <FormHelperText>{errors.mediaType.message}</FormHelperText>
                                    )}
                                  </FormControl>
                                )}
                              />
                            </MDBox>

                            {/* YtUrl Field */}
                            <MDBox
                              sx={{ my: 2, display: mediaType === "youtube" ? "block" : "none" }}
                            >
                              <Controller
                                name="ytUrl"
                                control={control}
                                // rules={{ required: "Youtube url is required" }}
                                rules={{
                                  required:
                                    mediaType === "youtube" ? "Youtube URL is required" : false,
                                  pattern:
                                    mediaType === "youtube"
                                      ? {
                                          value:
                                            /^(https?\:\/\/)?(www\.youtube\.com|youtu\.be)\/.+$/,
                                          message: "Invalid YouTube URL",
                                        }
                                      : undefined,
                                }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Youtube url"
                                    fullWidth
                                    error={!!errors.ytUrl}
                                    helperText={errors.ytUrl?.message}
                                  />
                                )}
                              />
                            </MDBox>

                            {/* Title Field */}
                            <MDBox sx={{ my: 2 }}>
                              <Controller
                                name="title"
                                control={control}
                                rules={{ required: "Title is required" }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Title"
                                    fullWidth
                                    error={!!errors.title}
                                    helperText={errors.title?.message}
                                  />
                                )}
                              />
                            </MDBox>

                            {/* Description Field */}
                            <MDBox sx={{ my: 2 }}>
                              <Controller
                                name="description"
                                control={control}
                                rules={{ required: "Description is required" }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    multiline
                                    maxRows={4}
                                    label="Description"
                                    fullWidth
                                    error={!!errors.description}
                                    helperText={errors.description?.message}
                                  />
                                )}
                              />
                            </MDBox>

                            {/* Steps Field */}
                            {/* <MDBox sx={{ mb: 2 }}>
                              <Controller
                                name="step"
                                control={control}
                                rules={{ required: "Steps is required" }}
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    label="Step"
                                    fullWidth
                                    type="number"
                                    error={!!errors.step}
                                    helperText={errors.step?.message}
                                  />
                                )}
                              />
                            </MDBox> */}

                            {/* Submit Button */}
                            <MDBox sx={{ textAlign: "end" }}>
                              <MDButton
                                type="submit"
                                sx={{ textAlign: "center", mt: 2 }}
                                variant="gradient"
                                color="primary"
                              >
                                {loading ? "Loading..." : isEditable ? "Edit" : "Create"}
                              </MDButton>
                            </MDBox>
                          </form>
                        </Stack>{" "}
                      </MDBox>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            {/* <AlertConfirm
              open={alertOpen}
              title="Create"
              context="Would you like to add a custom user?"
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  //   console.log(data);
                  //   console.log(data?.profilePic);
                  //   if (!username || !firstName) {
                  //     return alert("Username or first name should not be empty");
                  //   }
                  //   const formData = new FormData();
                  //   formData.append("name", data.name);
                  //   formData.append("username", data.username);
                  //   formData.append("loginType", "MetaMask");
                  //   formData.append("profilePic", data.profilePic);
                  //   // console.log(formData, "FormData");
                  //   const res = await createUser(formData);
                  //   console.log(res);
                  //   setAlertOpen(false);
                  //   alert("User added successfully!");
                  //   setOpen(false);
                  //   setTimeout(() => {
                  //     window.location.reload();
                  //   }, 300);
                } catch (error) {
                  console.log(error);
                  alert("Something went wrong!");
                }
              }}
            />

            <AlertMessage
              open={isAlertMessgOpen}
              setOpen={setIsAlertMessg}
              severity="Error"
              message="Something went wrong!"
            /> */}
          </Box>
        </Fade>
      </Modal>
    </>
  );
}
