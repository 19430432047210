import { Button } from "@mui/material";
import AlertConfirm from "components/AlertConfirm";
import React from "react";
import { deleteHowToPlaySteps } from "services/api/gameAPI";

const DeleteHowToPlayDialog = ({ row, handleRefetchData }) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        sx={{ marginBottom: "20px", textTransform: "none" }}
        onClick={handleOpen}
      >
        Delete
      </Button>

      <AlertConfirm
        open={open}
        title="Create"
        context="Do you want to delete this?"
        onClose={() => setOpen(!open)}
        onAccept={async () => {
          try {
            console.log(row);
            const res = await deleteHowToPlaySteps(row?._id);
            console.log(res);
            alert("How to play step deleted successfully!");
            setOpen(false);
            if (handleRefetchData) handleRefetchData();
          } catch (error) {
            console.error(error);
            alert("Something went wrong!");
          }
        }}
      />
    </div>
  );
};

export default DeleteHowToPlayDialog;
