/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
// Crypto Champs React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import Cookies from "js-cookie";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { IconButton, InputAdornment } from "@mui/material";
import Logo from "./favicon.png";
import { logInModule } from "services/api/gameAPI";
import PhoneVerify from "../../../components/PhoneVerify";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { auth } from "../../../utils/firebaseConfig";
import { getAuthorizedNumbers } from "services/api/gameAPI";

function SignIn() {
  Cookies.remove("token");
  sessionStorage.removeItem("permissions");
  sessionStorage.removeItem("username");
  localStorage.removeItem("access");
  const navigate = useNavigate();
  // const [rememberMe, setRememberMe] = useState(false);
  const [userName, setUserName] = useState("");
  const [Password, setPassword] = useState("");
  // const [access, setAccess] = useState(null);
  // const [token, setToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [otp, setOtp] = useState(" ");
  const [phone, setPhone] = useState("");
  const [enableOtp, setEnableOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  // const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const handleShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleSignIn = async (e) => {
    e?.preventDefault();

    const data = {
      username: userName,
      password: Password,
    };

    try {
      setLoading(true);
      const response = await logInModule(data);
      // .then((response) => {
      // localStorage.setItem("access", JSON.stringify(response.data.access_token));
      Cookies.set("token", response.data.access_token);
      sessionStorage.setItem("token", response.data.access_token);
      sessionStorage.setItem("username", response.data.username);
      sessionStorage.setItem("permissions", response.data.permissions);
      console.log(response.data, "response");
      navigate("/dashboard");
      setLoading(false);
    } catch (error) {
      // })
      // .catch((error) => {
      console.log(error);
      document.getElementById("error").innerHTML = "Wrong Login Credential";
      alert("Incorrect username or password. Please try again.");
      setLoading(false);
    }

    // });
  };

  const handlePhoneLogin = async (e) => {
    // event.preventDefault();
    setLoading(true);

    if (loading) {
      console.log("loading...");
      return;
    }
    try {
      const response = await getAuthorizedNumbers({ phone: phone });

      if (!response) {
        alert("Invalid phone number. Please try again.");
        setLoading(false);
        return;
      }

      console.log(window.recaptchaVerifier, "recaptchaVerifier");
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      } else {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,

          "recaptcha-container",
          {
            size: "invisible",
          }
        );
      }

      let recaptchaVerifier = window.recaptchaVerifier;
      // setPhoneResult(phone);
      const formatPh = "+" + phone;

      let confirmation = await signInWithPhoneNumber(auth, formatPh, recaptchaVerifier);

      if (confirmation) {
        window.confirmationResult = confirmation;
        // setnumberState("otp");
        alert("OTP sent successfully");
        setEnableOtp(true);
      }
    } catch (error) {
      console.log(error, "ERROR");
      if (error?.response?.data?.error) {
        alert(error?.response?.data?.error);
      } else {
        alert("Something went wrong. Please try again.");
      }
    }
    setLoading(false);
  };

  const handleOTPVerify = (e) => {
    setLoading(true);

    if (loading) {
      console.log("loading...");
      return;
    }

    if (otp.length < 6) {
      setLoading(false);
      return alert("Invalid OTP. Please double-check the code you entered and try again.");
    }

    try {
      window.confirmationResult
        .confirm(otp)
        .then(async (result) => {
          // sessionStorage.setItem("token", result.user.accessToken);
          // const token = sessionStorage.getItem("token");

          // console.log(token, "token");
          // await handlePhoneLogin(event);
          setIsVerified(true);
          setEnableOtp(false);

          await handleSignIn(e);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);

          return alert("Invalid OTP. Please double-check the code you entered and try again.");
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
      return alert("Something went wrong");
      // return toast.error(
      //   "Invalid OTP. Please double-check the code you entered and try again."
      // );
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            <img src={Logo} alt="logo" />
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                // type="email"
                label="Username"
                onChange={(e) => setUserName(e.target.value)}
                fullWidth
                autoComplete="off"
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                fullWidth
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}></MDBox>

            <MDBox mb={2}>
              <div id="recaptcha-container" stlye={{ position: "absolute", zIndex: "999" }}></div>
              <PhoneVerify
                phone={phone}
                otp={otp}
                setOtp={setOtp}
                enableOtp={enableOtp}
                isVerified={isVerified}
                onChange={(e) => {
                  setPhone(e);

                  console.log(e);
                  if (e?.length <= 10) {
                    setEnableOtp(false);
                  }
                }}
              />
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={-1}></MDBox>
            <MDBox mt={1} mb={1}>
              {/* <MDButton variant="gradient" color="dark" onClick={handleSignIn} fullWidth>
                Verify
              </MDButton> */}

              <MDButton
                variant="gradient"
                color="dark"
                disabled={!phone}
                // onClick={handleSignIn}
                onClick={(e) => {
                  // handleSignIn();
                  // setEnableOtp(true);
                  e?.preventDefault();
                  // handleSignIn(e);

                  console.log(enableOtp, "enableOtp");
                  if (enableOtp) {
                    handleOTPVerify();
                  } else {
                    if (isVerified) {
                      handleSignIn();
                      return;
                    } else {
                      handlePhoneLogin();
                    }
                  }
                }}
                fullWidth
              >
                {loading
                  ? "Loading..."
                  : enableOtp
                  ? "Continue"
                  : isVerified
                  ? "Sign in"
                  : "Verify"}
              </MDButton>

              <MDTypography
                display="inline"
                variant="h6"
                textTransform="capitalize"
                fontWeight="small"
                id="error"
                sx={{ color: "red" }}
              ></MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default SignIn;
