/**

=========================================================

* Crypto Champs React - v2.1.0

=========================================================

 

* Product Page: https://www.creative-tim.com/product/material-dashboard-react

* Copyright 2022 Creative Tim (https://www.creative-tim.com)

 

Coded by www.creative-tim.com

 

 =========================================================

 

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// @mui material components

import React, { useState, useEffect } from "react";

// import axios from "axios";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
} from "@mui/material";

import { color, styled } from "@mui/system";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";

import MDBox from "components/MDBox";

import MDInput from "components/MDInput";

// import MDButton from "components/MDButton";

import { Link } from "react-router-dom";

import { rewardData } from "services/api/gameAPI";
import MDButton from "components/MDButton";
import zipcelx from "zipcelx";
import ExportData from "components/Export/ExportData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,

  borderBottom: "none",
}));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(even)": {
//     backgroundColor: theme.palette.action.hover,
//   },
// }));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function RewardTable() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [tableLoading, setTableLoading] = useState(false);

  const [exportLoader, setExportLoader] = useState(false);

  const columns = [
    {
      label: "Date",
      value: (user) =>
        new Date(user?.createdAt).toLocaleString("en-US", {
          timeZone: "America/New_York",
        }) || "",
    },
    { label: "User", value: (user) => user?.userId?.username || "" },
    { label: "Transferred Token", value: (user) => user?.tokenCount || "0" },
    { label: "Payment Method", value: (user) => user?.paymentMethod || "" },
    { label: "Game", value: (user) => user?.populatedGame[0]?.gameName || "" },

    // { label: "User", value: (player) => player?.walletData?.map((dat) => dat?.username || "") },
  ];

  const downloadExcel = async (d) => {
    try {
      const headerRow = columns.map((column) => ({ value: column.label, type: "string" }));
      const dataRows =
        d?.map((user, search) =>
          columns.map((column) => ({
            value: column.value(user),
            type: "string",
          }))
        ) || [];
      const data = [headerRow].concat(dataRows);
      await zipcelx({ filename: `CryptoChamps admin transaction`, sheet: { data } });
    } catch (error) {
      console.error(error);
    }
  };

  const totalData = async (start, end) => {
    setExportLoader(true);
    try {
      let body = {};
      if (start && end) {
        body.startDate = start;
        body.endDate = end;
      }

      const response = await rewardData(
        { page: page + 1, limit: count <= 0 ? 1 : count, term },
        body
      );
      const data = response.data.results;
      await downloadExcel(data);

      setExportLoader(false);
      return true;
    } catch (error) {
      console.error(error);
    }
    setExportLoader(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      setTableLoading(true);
      try {
        const response = await rewardData({ page: page + 1, limit, term });

        setUsers(response.data.results);

        await console.log(response);

        setCount(response.data.count);
      } catch (error) {
        console.error(error);
      }
      setTableLoading(false);
    };

    fetchData();
  }, [page, limit, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);

    setPage(0);
  };

  const handletitle = (e) => {
    setTerm(e.target.value);

    setPage(0);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDBox margin="20px" pr={1}>
                <MDInput label="Search..." value={term} size="small" onChange={handletitle} />
              </MDBox>

              {/* <div style={{ margin: "20px" }}>
                <MDButton variant="contained" onClick={totalData} color="primary">
                  {exportLoader ? "Loading..." : "Export to Excel"}
                </MDButton>
              </div> */}

              <ExportData
                apiCall={async ({ start, end }) => {
                  console.log("apiCall", start, end);
                  return await totalData(start, end);
                }}
              />
            </div>

            <TableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>User </StyledTableCell>
                    <StyledTableCell>Transferred Token</StyledTableCell>
                    <StyledTableCell>PaymentMethod</StyledTableCell>

                    {/* <StyledTableCell>admin</StyledTableCell> */}

                    <StyledTableCell>Game </StyledTableCell>

                    {/* Add more table headers as needed */}
                  </TableRow>
                </StyledTableHead>

                <TableBody>
                  {tableLoading ? (
                    <TableRow>
                      <TableCell colSpan={12} align="center">
                        <Typography
                          sx={{ m: 3 }}
                          textAlign="center"
                          id="transition-modal-title"
                          variant="h4"
                          component="h2"
                          fontSize={20}
                        >
                          Loading...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    users?.map((user) => (
                      <TableRow key={user?._id}>
                        <StyledTableCell>
                          {new Date(user?.createdAt).toLocaleString("en-US", {
                            timeZone: "America/New_York",
                          })}
                        </StyledTableCell>
                        <StyledTableCell>
                          <Link
                            to={`/profile/${user.userId?._id}`}
                            state={{ List: user?._id }}
                            style={{ color: "white", textDecoration: "none" }}
                          >
                            {user?.userId?.username}
                          </Link>
                        </StyledTableCell>
                        <StyledTableCell>{user?.tokenCount ?? 0}</StyledTableCell>

                        {/* <StyledTableCell>
  
                          <Link
  
                            to={`/profile/${user.adminId._id}`}
  
                            state={{ List: user._id }}
  
                            style={{ color: "white", textDecoration: "none" }}
  
                          >
  
                            {user.adminId.username}
  
                          </Link>
  
                        </StyledTableCell> */}

                        <StyledTableCell>{user?.paymentMethod ?? ""}</StyledTableCell>
                        <StyledTableCell>{user?.populatedGame[0]?.gameName ?? ""}</StyledTableCell>

                        {/* Add more table cells for other user properties */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={count}
            style={{ color: "white" }}
            rowsPerPage={limit}
            page={page}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleLimitChange}
          />
        </Grid>
      </Grid>

      <Footer />
    </DashboardLayout>
  );
}

export default RewardTable;
