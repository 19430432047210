import PropTypes from "prop-types";
import isString from "lodash/isString";
import { useDropzone } from "react-dropzone";
// @mui
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
//
import Image from "./Image";
// import Iconify from '../Iconify';
import RejectionFiles from "./RejectionFiles";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  width: 144,
  height: 144,
  margin: "auto",
  borderRadius: "50%",
  padding: 9,
  // border: `1px dashed grey`,
}));

const DropZoneStyle = styled("div")({
  zIndex: 0,
  width: "100%",
  height: "100%",
  outline: "none",
  display: "flex",
  overflow: "hidden",
  borderRadius: "50%",
  position: "relative",
  alignItems: "center",
  justifyContent: "center",
  "& > *": { width: "100%", height: "100%" },
  "&:hover": {
    cursor: "pointer",
    "& .placeholder": {
      zIndex: 9,
    },
  },
});

const PlaceholderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "absolute",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  color: "grey",
  backgroundColor: "#1a1919",
  transition: theme.transitions.create("opacity", {
    easing: "easeInOut",
    duration: theme.transitions.duration.shorter,
  }),
  "&:hover": { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

UploadAvatar.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  helperText: PropTypes.node,
  sx: PropTypes.object,
  networkImage: PropTypes.string, // Add PropTypes for networkImage
};

export default function UploadAvatar({
  error,
  file,
  helperText,
  sx,
  networkImage, // Destructure networkImage from props
  ...other
}) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  const imageSrc = file ? (isString(file) ? file : file.preview) : networkImage;

  return (
    <>
      <RootStyle
        sx={{
          ...((isDragReject || error) && {
            borderColor: "error.light",
          }),
          ...sx,
        }}
      >
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
          }}
        >
          <input {...getInputProps()} />

          {imageSrc && <Image alt="avatar" src={imageSrc} sx={{ zIndex: 999 }} />}

          <PlaceholderStyle
            className="placeholder"
            sx={{
              ...(imageSrc && {
                opacity: 0,
                color: "common.white",
                bgcolor: "grey.900",
                "&:hover": { opacity: 0.72 },
              }),
              ...((isDragReject || error) && {
                bgcolor: "error.lighter",
              }),
            }}
          >
            <AddAPhotoIcon sx={{ mb: 1, width: 30, height: 30 }} />

            <Typography variant="caption">{imageSrc ? "Update photo" : "Upload photo"}</Typography>
          </PlaceholderStyle>
        </DropZoneStyle>
      </RootStyle>

      {helperText && helperText}

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
}
