/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  TablePagination,
  Button,
  Autocomplete,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Box, styled, width } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { walletData } from "services/api/gameAPI";
import { searchUser } from "services/api/gameAPI";
import { useStyles } from "layouts/tokenDistribution";
import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import { Link } from "react-router-dom";
import { walletData24Days } from "services/api/gameAPI";
import MDBadge from "components/MDBadge";
import ExportData from "components/Export/ExportData";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function WalletTransaction() {
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  let [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [query, setQuery] = useState("");
  const [trigger, setTrigger] = useState(false);
  const [last24Hours, setLast24Hours] = useState(true);
  const [loading, setLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [winnerType, setWinnerType] = useState(null);
  const [transactionType, setTransactionType] = useState(null);
  const [searchType, setSearchType] = useState(null);
  const [searchedGameId, setSearchedGameId] = useState(null);
  const [exportLoader, setExportLoader] = useState(null);

  const searchByItems = ["User", "Game Id"];

  useEffect(() => {
    const fetchData = async () => {
      let response = [];
      let body = {};

      setTableLoading(true);
      try {
        body.transactionType = transactionType ?? "None";
        body.winnerType = winnerType ?? "None";

        if (searchedGameId) {
          body.sGameInstanceId = searchedGameId;
        }
        // if (!trigger) {
        // if (!last24Hours) {
        //   setLast24Hours(true);
        // }
        response = await walletData({ page: page + 1, limit, term, last24Hours }, body);
        // } else {
        //   response = await walletData24Days({ page: page + 1, limit, term });
        // }

        setUsers(response.data.data.results);
        await console.log(response);
        await setCount(response.data.data.count);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }

      setTableLoading(false);
    };

    fetchData();
  }, [page, limit, term, trigger]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  function handleSuggestionClick(suggestion) {
    setQuery(suggestion?.username);
    console.log(suggestion);
    //setTitleName(suggestion?.username)
    setTerm(suggestion?._id);

    // let url = walletData({ page: page + 1, limit, term, last24Hours: false });
    // let url = walletData({ page: page + 1, limit, term, last24Hours });
    // const res = fetch(url)
    //   .then((response) => response.json())
    //   .then((data) => {
    //     // setSearch(data);
    //     console.log(data, "data");
    //     setTerm(data?.results);
    //     setCount(data?.count);
    //   });
    setTrigger(!trigger);

    setSuggestions([]);
  }
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  const handleTitle = async (e) => {
    // setTerm(e.target.value);
    // setPage(0);
    const newQuery = e.target.value;

    // if (newQuery == -null) {
    //   ledgerData(page, limit);
    // }
    // console.log(newQuery);

    console.log(searchType);
    if (searchType == searchByItems[1]) {
      setSearchedGameId(newQuery);
      setQuery("");
      // setTerm("");
    } else {
      setQuery(newQuery);
      const newSuggestions = await fetchSuggestions(newQuery);
      console.log(newSuggestions);
      setSuggestions(newSuggestions);
      setSearchedGameId(null);
    }
    setTrigger(!trigger);
  };

  async function fetchSuggestions(query) {
    const response = await searchUser(query);
    // const data = await response.json();
    setTrigger(!trigger);
    return response.data;
  }

  const validateTransactionHash = (hash, type) => {
    if (!hash) return "";

    switch (type) {
      case "USDC":
        return `https://polygonscan.com/tx/${hash}`;
      case "CHAMPS":
        return `https://basescan.org/tx/${hash}`;
      default:
        return hash;
    }
  };

  const validateWithdrawWallet = (wallet, transactionType) => {
    if (!wallet) return "";

    switch (transactionType) {
      case "Withdrawal":
        return wallet;
      case "ChampsWithdraw":
        return wallet;
      default:
        return "";
    }
  };

  const columns = [
    {
      label: "Date",
      value: (player) =>
        new Date(player?.created).toLocaleString("en-US", {
          timeZone: "America/New_York",
        }) || "",
    },
    {
      label: "Game ID",
      value: (player) =>
        player?.gameInstance?._id
          ?.substring(player?.gameInstance?._id?.length - 7)
          ?.toUpperCase() || "",
    },
    { label: "Game Type", value: (player) => player?.gameInstance?.game?.winnerType || "" },
    { label: "User Name", value: (player) => player?.userId?.username || "" },
    { label: "Transaction Type", value: (player) => player.type || "" },
    { label: "Credited Token", value: (player) => player?.added || "0" },
    { label: "Debited Token", value: (player) => player.reduced || "0" },
    { label: "Status", value: (player) => player?.status || "" },
    { label: "Email", value: (player) => player?.userId?.email || "" },
    { label: "Phone No", value: (player) => player?.userId?.phone || "" },
    {
      label: "Profile Wallet",
      value: (player) =>
        player?.userId?.loginWalletAddress === "null"
          ? ""
          : player?.userId?.loginWalletAddress || "",
    },
    {
      label: "Magic Wallet",
      value: (player) =>
        player?.userId?.customUser === true
          ? ""
          : player?.userId?.customWalletAddress === "null"
          ? /** */ ""
          : /** */ player?.userId?.customWalletAddress || "",
    },
    {
      label: "Withdraw wallet",
      // value: (player) => (player?.walletAddress === "null" ? "" : player?.walletAddress || ""),
      value: (player) => validateWithdrawWallet(player?.walletAddress, player?.type),
    },
    {
      label: "Transaction hash",
      value: (player) => validateTransactionHash(player?.transactionHash, player?.paymentMethod),
    },

    // { label: "User", value: (player) => player?.walletData?.map((dat) => dat?.username || "") },
  ];
  const downloadExcel = (d) => {
    const headerRow = columns.map((column) => ({ value: column.label, type: "string" }));
    const dataRows =
      d?.map((player, search) =>
        columns.map((column) => ({
          value: column.value(player),
          type: "string",
        }))
      ) || [];
    const data = [headerRow].concat(dataRows);
    zipcelx({ filename: `CryptoChamps wallet transaction`, sheet: { data } });
  };

  const totalData = async (start, end) => {
    setExportLoader(true);

    if (exportLoader) return;

    let body = {};
    body.transactionType = transactionType ?? "None";
    body.winnerType = winnerType ?? "None";

    if (start && end) {
      body.startDate = start;
      body.endDate = end;
    }

    if (searchedGameId) {
      body.sGameInstanceId = searchedGameId;
    }
    // if (!trigger) {
    // if (!last24Hours) {
    //   setLast24Hours(true);
    // }

    console.log(body, "body");
    try {
      const data = await walletData(
        { page: page + 1, limit: count <= 0 ? 1 : count, term, last24Hours },
        body
      );

      // setSearch(data);
      console.log(data, "data");
      const results = data?.data?.data?.results ?? [];

      if (results.length === 0) {
        setExportLoader(false);
        return alert("No data to export");
      }

      // setTotal(data);
      await downloadExcel(data.data.data.results);

      setExportLoader(false);
      return true;
    } catch (error) {
      console.error(error);
    }

    setExportLoader(false);
  };

  const latestData = async () => {
    setTrigger(!trigger);
    console.log(trigger);
    setLast24Hours(!last24Hours);
  };

  const resetData = async () => {
    // console.log("Inn");
    setWinnerType(null);
    setTransactionType(null);
    setTrigger(!trigger);
    setLast24Hours(true);
    setQuery("");
    setSuggestions([]);
    setTerm("");
    setSearchedGameId(null);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            <>
              <Grid item xs={12}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      // justifyContent: "space-evenly",
                      padding: "12px -1px",
                    }}
                  >
                    <MDButton
                      style={{ margin: "20px", width: "200px" }}
                      variant="contained"
                      onClick={latestData}
                      color="secondary"
                    >
                      {!last24Hours ? <div>Last 24 Hours Data</div> : <div>All Data</div>}
                    </MDButton>
                    <MDBox margin="20px" pr={1}>
                      <Autocomplete
                        disablePortal
                        // disableClearable={true}
                        id="combo-box-demo"
                        sx={{ width: 180 }}
                        options={searchByItems}
                        getOptionLabel={(option) => `${option}`}
                        onChange={(event, newValue) => {
                          // onChange(Number(newValue?.label) || "");
                          setSearchType(newValue);
                          setQuery("");
                          setSuggestions([]);
                          setTerm("");
                          setSearchedGameId(null);
                          setTrigger(!trigger);
                          //setting type value to state for validating the cryptos count
                        }}
                        value={searchType}
                        defaultValue={"Search by user"}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            label="Search by"
                            variant="outlined"
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 45,
                              },
                              // "& .MuiInputBase-root.Mui-disabled": {
                              //   backgroundColor: "#ffffff",
                              // },
                              // "& .MuiInputBase-root.Mui-disabled .MuiInputBase-input": {
                              //   color: "#ffffff", // Change this to your desired text color
                              //   WebkitTextFillColor: "#ffffff", // This is needed for WebKit browsers
                              //   borderColor: "#ffffff",
                              // },
                              // "& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline":
                              //   {
                              //     borderColor: "rgba(253, 254, 254, 0.36)", // Optional: change border color if needed
                              //   },
                            }}
                            // InputProps={{
                            //   readOnly: true,
                            // }}
                            // disabled={true}
                          />
                        )}
                      />
                    </MDBox>
                    {/* <MDTypography component="label" style={{ marginRight: "120px" }}>
                      </MDTypography> */}

                    <MDBox margin="20px" pr={1}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        sx={{ width: 160 }}
                        options={["Biggest Winner", "Biggest Loser"]}
                        getOptionLabel={(option) => `${option}`}
                        onChange={(event, newValue) => {
                          // onChange(Number(newValue?.label) || "");
                          setWinnerType(newValue);
                          setTrigger(!trigger);
                          //setting type value to state for validating the cryptos count
                        }}
                        value={winnerType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            label="Game Type"
                            variant="outlined"
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 45,
                              },
                            }}
                          />
                        )}
                      />
                    </MDBox>
                    <MDBox margin="20px" pr={1}>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        sx={{ width: 155 }}
                        options={["Entry Fees", "Prize", "Refund", "Withdrawal", "Deposit"]}
                        getOptionLabel={(option) => `${option}`}
                        onChange={(event, newValue) => {
                          // onChange(Number(newValue?.label) || "");
                          setTransactionType(newValue);
                          setTrigger(!trigger);
                          //setting type value to state for validating the cryptos count
                        }}
                        value={transactionType}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            id="outlined-basic"
                            label="Transaction Type"
                            variant="outlined"
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 45,
                              },
                            }}
                          />
                        )}
                      />
                    </MDBox>

                    {/* )} */}
                  </div>

                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <MDBox margin="20px" pr={1}>
                      <MDInput
                        label="Search..."
                        value={searchType === searchByItems[1] ? searchedGameId : query}
                        size="small"
                        sx={{
                          width: 250,
                        }}
                        onChange={handleTitle}
                        InputProps={{
                          style: {
                            height: "40px", // Desired height
                          },
                        }}
                      />
                    </MDBox>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <MDButton
                        style={{ margin: "20px" }}
                        variant="contained"
                        onClick={resetData}
                        color="secondary"
                      >
                        {<div>Reset</div>}
                      </MDButton>
                      {/* {username === "admin" && ( */}
                      {/* <div style={{ margin: "20px" }}>
                        <MDButton variant="contained" onClick={totalData} color="primary">
                          {exportLoader ? "Loading..." : "Export to Excel"}
                        </MDButton>
                      </div> */}

                      <ExportData
                        apiCall={async ({ start, end }) => {
                          console.log("apiCall", start, end);
                          return await totalData(start, end);
                        }}
                      />
                    </div>
                  </div>
                  {query === "" ? (
                    ""
                  ) : (
                    <div id="search-card" className={classes.searchCard}>
                      <Paper>
                        <List id="suggestion-list">
                          {suggestions.map((suggestion) => (
                            <ListItem
                              key={suggestion._id}
                              button
                              className={classes.suggestionItem}
                              onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion?.username}
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </div>
                  )}

                  <TableContainer>
                    {users.length === 0 ? (
                      <>
                        <Typography
                          sx={{ m: 3 }}
                          textAlign="center"
                          id="transition-modal-title"
                          variant="h4"
                          component="h2"
                          fontSize={20}
                        >
                          There are no records at the moment.
                        </Typography>
                        {/* {!trigger ?
                    <Button sx={{ position: "absolute", top: "50%", left: "45%", textTransform: "none" }} variant="contained" onClick={() => setTrigger(!trigger)} >Go Back</Button>
                    : <></>} */}
                      </>
                    ) : (
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell>Game ID</StyledTableCell>
                            <StyledTableCell>Game Type</StyledTableCell>
                            <StyledTableCell>User Name</StyledTableCell>
                            <StyledTableCell>Transaction Type</StyledTableCell>
                            {/* <StyledTableCell>From Admin</StyledTableCell> */}
                            {/* <StyledTableCell>Balance</StyledTableCell> */}
                            <StyledTableCell>Credited Token</StyledTableCell>
                            <StyledTableCell>Debited Token</StyledTableCell>
                            <StyledTableCell>Payment method</StyledTableCell>
                            <StyledTableCell>Transaction hash</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>

                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>

                        <TableBody>
                          {tableLoading ? (
                            <StyledTableRow>
                              <TableCell colSpan={12} align="center">
                                <Typography
                                  sx={{ m: 3 }}
                                  textAlign="center"
                                  id="transition-modal-title"
                                  variant="h4"
                                  component="h2"
                                  fontSize={20}
                                >
                                  Loading...
                                </Typography>
                              </TableCell>
                            </StyledTableRow>
                          ) : (
                            users?.map((user) => (
                              <StyledTableRow key={user._id}>
                                <StyledTableCell>
                                  {new Date(user?.created).toLocaleString("en-US", {
                                    timeZone: "America/New_York",
                                  })}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {" "}
                                  {user?.gameInstance?._id
                                    ?.substring(user?.gameInstance?._id?.length - 7)
                                    ?.toUpperCase()}
                                </StyledTableCell>
                                <StyledTableCell>
                                  {user?.gameInstance?.game?.winnerType ?? ""}
                                </StyledTableCell>
                                <StyledTableCell>
                                  <Link
                                    // to={`/profile/${user?.walletData?.map((dat) => dat?._id)}`}
                                    to={`/profile/${user?.userId?._id}`}
                                    state={{ List: user._id }}
                                    style={{ color: "white", textDecoration: "none" }}
                                  >
                                    {/* {user?.walletData?.map((dat) => dat?.username)} */}
                                    {user?.userId?.username}
                                  </Link>
                                </StyledTableCell>
                                <StyledTableCell>{user.type}</StyledTableCell>

                                {/* <StyledTableCell>{user?.fromAdmin ? "Yes" : "No"}</StyledTableCell> */}

                                {/* <StyledTableCell>{user?.t4lToken}</StyledTableCell> */}
                                {/* <StyledTableCell>{user?.added}</StyledTableCell>  */}
                                {/* <StyledTableCell>{user.reduced}</StyledTableCell> */}

                                <StyledTableCell>
                                  {
                                    user?.added < 0.00001 && user?.added > 0
                                      ? "< 0.01"
                                      : user?.added === 0
                                      ? "0"
                                      : parseFloat(user?.added?.toFixed(5)) // Removes trailing zeros for non-zero values
                                  }
                                </StyledTableCell>
                                <StyledTableCell>
                                  {" "}
                                  {
                                    user?.reduced < 0.01 && user?.reduced > 0
                                      ? "< 0.01"
                                      : user?.reduced === 0
                                      ? "0"
                                      : parseFloat(user?.reduced?.toFixed(5)) // Removes trailing zeros for non-zero values
                                  }
                                </StyledTableCell>
                                <StyledTableCell>{user?.paymentMethod ?? ""}</StyledTableCell>
                                <StyledTableCell>{user?.transactionHash ?? ""}</StyledTableCell>

                                <StyledTableCell>
                                  <MDBox ml={-1}>
                                    <MDBadge
                                      badgeContent={user?.status}
                                      color={user?.status === "completed" ? "success" : "info"}
                                      variant="gradient"
                                      size="sm"
                                    />
                                  </MDBox>
                                </StyledTableCell>

                                {/* Add more table cells for other user properties */}
                              </StyledTableRow>
                            ))
                          )}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </>
          </Grid>

          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default WalletTransaction;
