/* eslint-disable no-underscore-dangle */
/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
// import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Avatar,
  Typography,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "../../components/MDButton";
import { Link } from "react-router-dom";
import { getCustomUsers } from "services/api/gameAPI";
import zipcelx from "zipcelx";
import MDTypography from "components/MDTypography";
import CreateCustomUser from "./createUser";
import AlertConfirm from "components/AlertConfirm";
import { disableFakeUser } from "services/api/gameAPI";
import { deleteFakeUser } from "services/api/gameAPI";
import Tooltip from "@mui/material/Tooltip";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function CustomUser() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [term, setTerm] = useState("");

  const [action, setAction] = useState("");
  const [disableAlertOpen, setDisableAlertOpen] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const [allData, setAllData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCustomUsers({ page: page + 1, limit, term });
        console.log(response, "res");
        setUsers(response.data.data.users);
        console.log(response.data.users);
        setCount(response.data.data.totalCount);
        setAllData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [page, limit, term]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handletitle = (e) => {
    setTerm(e.target.value);
    setPage(0);
  };
  const columns = [
    { label: "username", value: (player) => player.username || "" },
    { label: "email", value: (player) => player.email || "" },
    { label: "Phone", value: (player) => player.phone || "" },
    // { label: "gender", value: (player) => player?.gender || "" },
    { label: "name", value: (player) => player?.name || "" },
  ];
  const downloadExcel = (d) => {
    console.log("d", d);
    const headerRow = columns?.map((column) => ({ value: column.label, type: "string" }));
    const dataRows = d.users?.map((player, search) =>
      columns?.map((column) => ({
        value: column.value(player),
        type: "string",
      }))
    );
    const data = [headerRow].concat(dataRows);
    zipcelx({ filename: `420 wallet transaction`, sheet: { data } });
  };
  const totalData = async () => {
    const data = await getCustomUsers({ page: page + 1, limit: count, term });

    // setSearch(data);
    console.log(data, "data");
    // setTotal(data);
    await downloadExcel(data.data.data);
  };
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {loading ? (
        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>Loading...</div>
      ) : (
        <>
          <Grid container spacing={6}>
            {/* {users.length === 0 ? (
              <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                There are no records at the moment.
              </div>
            ) : ( */}
            <>
              <Grid item xs={12}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "12px -1px",
                    }}
                  >
                    <MDBox margin="20px" pr={1}>
                      <MDInput
                        label="Search user"
                        value={term}
                        size="small"
                        onChange={handletitle}
                      />
                    </MDBox>
                    <div style={{ marginTop: "20px" }}>
                      <CreateCustomUser />
                    </div>
                    <div style={{ margin: "20px" }}>
                      <MDButton
                        disabled={users?.length === 0}
                        variant="contained"
                        onClick={totalData}
                        color="primary"
                      >
                        Export to Excel
                      </MDButton>
                    </div>
                  </div>

                  <Typography
                    variant="caption"
                    sx={{
                      textAlign: "start",
                      color: "white !important",
                      marginLeft: "20px",
                      display: "block", // Ensures correct alignment
                    }}
                  >
                    {"Free Bot Users: "} {allData?.freeBotUserCount} <br />
                    {"Busy Bot Users: "} {allData?.busyBotUserCount}
                  </Typography>
                  <TableContainer>
                    {users?.length === 0 ? (
                      <>
                        {" "}
                        <div style={{ color: "#FFF", paddingLeft: "35%", paddingBottom: "20%" }}>
                          There are no records at the moment.
                        </div>
                      </>
                    ) : (
                      <Table>
                        <StyledTableHead>
                          <TableRow>
                            <StyledTableCell>
                              {"Name/"}
                              {"Username"}
                            </StyledTableCell>
                            <StyledTableCell>Availability</StyledTableCell>
                            <StyledTableCell>Wallet address</StyledTableCell>
                            <StyledTableCell>POL token</StyledTableCell>
                            <StyledTableCell>USDC token</StyledTableCell>
                            <StyledTableCell>Status</StyledTableCell>
                            <StyledTableCell>View</StyledTableCell>
                            {/* Add more table headers as needed */}
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {users?.map((user) => (
                            <StyledTableRow key={user._id}>
                              {/* <StyledTableCell>{user.username}</StyledTableCell> */}
                              <StyledTableCell
                                sx={{ display: "flex", alignItems: "center", gap: 1 }}
                              >
                                <Avatar src={user?.profilePic} />
                                {user.name}
                                {" / "}
                                {user.username}
                              </StyledTableCell>
                              <StyledTableCell>
                                <MDButton
                                  color={user?.participantLength <= 0 ? "success" : "error"}
                                >
                                  {user?.participantLength <= 0 ? "Free" : "Busy"}
                                </MDButton>
                              </StyledTableCell>

                              <StyledTableCell>{user.loginWalletAddress}</StyledTableCell>
                              <StyledTableCell>
                                <Tooltip arrow title={user?.maticToken} sx={{ cursor: "pointer" }}>
                                  {parseFloat(user?.maticToken?.toFixed(4) ?? 0)}
                                </Tooltip>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Tooltip arrow title={user?.token} sx={{ cursor: "pointer" }}>
                                  {parseFloat(user?.token?.toFixed(4) ?? 0)}
                                </Tooltip>
                              </StyledTableCell>
                              <StyledTableCell>
                                <MDButton
                                  color={user?.disableAcc ? "error" : "info"}
                                  onClick={() => {
                                    setSelectedUserId(user?._id);
                                    setDisableAlertOpen(true);
                                    if (user?.disableAcc) {
                                      setAction("enable");
                                    } else {
                                      setAction("disable");
                                    }
                                  }}
                                >
                                  {user?.disableAcc ? "Enable" : "Disable"}
                                </MDButton>
                              </StyledTableCell>
                              <StyledTableCell>
                                <Link to={`/profile/${user._id}`} state={{ List: user._id }}>
                                  <MDButton color="info">VIEW</MDButton>
                                </Link>
                              </StyledTableCell>

                              {/* Not Needed for now */}
                              {/* <StyledTableCell>
                                <MDButton
                                  color={"info"}
                                  onClick={() => {
                                    setSelectedUserId(user?._id);
                                    setDeleteAlertOpen(true);
                                  }}
                                >
                                  Delete
                                </MDButton>
                              </StyledTableCell> */}
                              {/* Add more table cells for other user properties */}
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    )}
                  </TableContainer>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 15]}
                  component="div"
                  count={count}
                  style={{ color: "white" }}
                  rowsPerPage={limit}
                  page={page}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                />
              </Grid>
            </>
            {/* )} */}

            <AlertConfirm
              open={disableAlertOpen}
              title="Disable"
              context={`Do you want ${action} user?`}
              onClose={() => setDisableAlertOpen(!disableAlertOpen)}
              onAccept={async () => {
                try {
                  const response = await disableFakeUser(selectedUserId);
                  console.log(response.data);

                  if (response?.data?.data) {
                    alert("The user has been successfully disabled.");
                    window.location.reload();
                  } else {
                    alert("Failed to disable the user. Please try again.");
                  }
                } catch (error) {
                  console.error("Error disabling user:", error);
                  alert("An unexpected error occurred. Please try again later.");
                }
              }}
            />

            {/* <AlertConfirm
              open={deleteAlertOpen}
              title="Delete"
              context={`Do you want to delete user?`}
              onClose={() => setDeleteAlertOpen(!deleteAlertOpen)}
              onAccept={async () => {
                try {
                  const response = await deleteFakeUser(selectedUserId);
                  console.log(response.data);

                  if (response?.data?.data) {
                    alert("The user has been successfully deleted.");
                    window.location.reload();
                  } else {
                    alert("Failed to delete the user. Please try again.");
                  }
                } catch (error) {
                  console.error("Error deleting user:", error);
                  alert("An unexpected error occurred. Please try again later.");
                }
              }}
            /> */}
          </Grid>
          <Footer />
        </>
      )}
    </DashboardLayout>
  );
}
export default CustomUser;
