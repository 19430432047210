import axios from "axios";
import Cookies from "js-cookie";

export const UN_AUTH_STATUS = "You have no authentication for this action";

const getToken = () => {
  return Cookies.get("token") ?? sessionStorage.getItem("token") ?? "Token is empty";
};

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

api.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getToken()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// let AlertBox = true;
// api.interceptors.response.use(
//   (response) => {
//     console.log("Response status:", response);

//     if (
//       (response.status === 200 || response.status === 201) &&
//       response.config.url !== "/admin/login" &&
//       response.config.url !== "/rewards/sendAdmin"
//     ) {
//       const { method } = response.config;

//       if (method === "post") {
//         // Show alert for successful creation
//         alert("Successfully created");
//         // Add your logic here to display the `data` JSX component
//       } else if (method === "delete") {
//         alert("Successfully deleted");
//       } else if (method === "edit") {
//         alert("Successfully edited");
//         window.location.reload(false);
//       }
//     }

//     return response;
//   },
//   async (error) => {
//     // Handle error here
//     if (error.response) {
//       const { status } = error.response;
//       if (status === 401 || status === 403) {
//         if (AlertBox) {
// alert(`${UN_AUTH_STATUS}`);
//           window.history.back();
//           AlertBox = await false;
//         }

//         return;
//       } else {
//         console.log(error);
//       }
//     }
//     console.error(error.response);
//     throw error;
//   }
// );

const upload = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    // Authorization: `Bearer ${sessionStorage.getItem("token")}`,
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
    "Content-type": "multipart/form-data",
  },
});

// 🛠️ Add token dynamically before each request
upload.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { api, upload };
